/* CourseCard.css */

.course-card {
  box-shadow: 0 5px 30px 1px rgb(232, 229, 229);
  background: rgba(255, 255, 255, 0.9);
  text-align: left;
  border-radius: 5px;
  overflow: hidden;
  margin: 1em auto;
  height: auto;
  width: 1000px;
  position: relative;
}

/* CourseCard.css */

.connect-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #7db7c1; /* Choose your desired background color */
  color: #fff; /* Text color */
  border: none;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  /* Remove underlines on the button */
}

.connect-button:hover {
  background-color: #00758d; /* Change background color on hover */
}

.course-details {
  position: relative;
  text-align: left;
  overflow: hidden;
  padding: 30px;
  height: 100%;
  float: left;
  width: 68%;
}

.course-card h1 {
  font-family: "Popins";
  display: inline-block;
  position: relative;
  font-size: 20px;
  color: #0f1217;
  margin-right: 100px;
}

.course-card p {
  font-family: "EB Garamond", serif;
  text-align: left;
  font-size: 18px;
  color: #3f3f3f;
}

.control {
  position: absolute;
  bottom: 20%;
  left: 22.8%;
}

.course-btn {
  transform: translateY(0px);
  transition: 0.3s linear;
  background: #809fff;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  border: none;
  color: #eee;
  padding: 0;
  margin: 0;
}

.course-btn:hover {
  transform: translateY(-6px);
  background: #1a66ff;
}

.course-btn span {
  font-family: "Farsan", cursive;
  transition: transform 0.3s;
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2em;
  margin: 0;
}

.course-btn .price,
.course-btn .shopping-cart {
  background: #333;
  border: 0;
  margin: 0;
}

.course-btn .price {
  transform: translateX(-10%);
  padding-right: 15px;
}

.course-btn .shopping-cart {
  transform: translateX(-100%);
  position: absolute;
  background: #333;
  z-index: 1;
  left: 0;
  top: 0;
}

.course-btn .buy {
  z-index: 3;
  font-weight: bolder;
}

.course-btn:hover .price {
  transform: translateX(-110%);
}

.course-btn:hover .shopping-cart {
  transform: translateX(0%);
}

.course-image {
  transition: all 0.3s ease-out;

  position: relative;
  overflow: hidden;
  height: 100%;
  float: right;
  width: 25%;
}

.course-card img {
  width: 100%;
  height: 100%;
}

.course-info {
  background: #7db7c1e6;
  font-family: "Popins", serif;
  transition: all 0.3s ease-out;
  transform: translateX(-100%);
  position: absolute;
  line-height: 1;
  text-align: left;
  font-size: 12px;
  cursor: no-drop;
  color: #fff;
  height: 100%;
  width: 100%;
  padding-left: 10px;
  top: 0;
  left: 0;
}

.course-info h2 {
  text-align: left;
}

.course-image:hover .course-info {
  transform: translateX(0);
}

.course-info ul li {
  transition: 0.3s ease;
}

.course-info ul li:hover {
  transform: translateX(50px) scale(1.3);
}

.course-image:hover img {
  transition: all 0.3s ease-out;
}

.course-image:hover img {
  transform: scale(1.2, 1.2);
}
