.filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 35vh;
  background: linear-gradient(to top, #dde1e9, #a2c9d7);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 50px;
  margin: 10px;
}

.filters-box {
  background-color: #7db7c190;
  padding: 15px;

  display: flex;
  width: 1000px; /* Set the desired fixed width */
  margin: auto; /* Center the box horizontally */
}

/* .search-input {
    width: 60%; 
    margin-bottom: 16px;
    padding: 15px;
    border: 1px solid #bdc8cd;
    border-radius: 15px;
    font-size: 16px;
    outline: none;
    box-shadow: inset 2px 2px 4px 0 rgba(255, 255, 255, 0.5),
      inset -2px -2px 4px 0 rgba(0, 0, 0, 0.1);
  } */

.search-container .search-input {
  background: transparent;
  border: none;
  outline: none;
  width: 0px;
  font-weight: 500;
  font-size: 16px;
  transition: 0.8s;
  width: 400px;
}
.search-container {
  background: #fff;
  height: 30px;
  border-radius: 30px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.8s;
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
}

.filters-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

label {
  margin-right: 8px;
  font-size: 16px;
  font-weight: bold;
}
select {
  flex: 1; /* Make all dropdowns equal length */
  padding: 1rem 2rem;
  border: 1px solid #bdc8cd;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  box-shadow: inset 2px 2px 4px 0 rgba(255, 255, 255, 0.5),
    inset -2px -2px 4px 0 rgba(0, 0, 0, 0.1);
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for long content */
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  margin-top: 16px; /* Add top margin to separate buttons from dropdowns */
}

button {
  flex: 1;
  padding: 10px 20px;
  font-size: 16px;
  margin: 10px;

  border-radius: 4px;
  background-color: #0b1b63;
  color: #faf7f6;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #151517;
}

buttonblock {
  display: inline-block;
  margin: 1em;
}

/* Header.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff; /* Background color for the navbar */
  padding: 10px 20px; /* Adjust padding as needed */
  color: #ffffff; /* Text color */
  border: #0b1b63;
}

.company-logo {
  width: 200px; /* Adjust the width of the logo */
}

.page-title {
  font-size: 32px; /* Adjust font size */
  margin: 0; /* Remove default margin */
  text-align: center;
  color: black;
}

.contact-button {
  background-color: #0b1b63; /* Button background color */
  color: #ffffff; /* Button text color */
  border: none;
  padding: 10px 20px; /* Adjust padding as needed */
  border-radius: 5px; /* Adjust border radius */
  cursor: pointer;
}

.contact-button:hover {
  background-color: #7db7c1; /* Button background color on hover */
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
}

.pagination li {
  display: inline-block;
  margin: 5px;
}

.pagination a {
  padding: 5px 10px;
  border: 1px solid #ccc;
  text-decoration: none;
  color: #333;
  border-radius: 3px;
}

.pagination .active a {
  background: #333;
  color: #fff;
}

.course-count {
  padding: 20px;
  text-align: right;
  margin-right: 100px;
}

/* Add this to your CSS */
.zoom-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-image: url("../images/arrow.png"); /* Replace with the path to your PNG image */
  background-size: cover; /* You can adjust this property to fit your needs */
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

#location-filter {
  width: 300px; /* Set the desired width for Location dropdown */
}

#university-filter {
  width: 620px;
  /* Set the desired width for University dropdown */
}

.mySelect__value-container {
  min-height: 51px;
}

#qualification-filter {
  width: 300px; /* Set the desired width for Qualification dropdown */
}

#nextintake-filter {
  width: 300px; /* Set the desired width for Next Intake dropdown */
}

#entryscore-filter {
  width: 300px; /* Set the desired width for Entry Score dropdown */
}

/* Add this to your CSS file or style block */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
